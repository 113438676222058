<template>
    <div class="container-operator-orders">
        <div class="operator-orders-header"
            :style="!showFilter ? 'box-shadow: 0px 8px 30px rgba(58, 46, 38, 0.1);' : `border-bottom: 1px solid #CFC4BE; margin-top: ${width > 535 ? '-161px' : '-140px'} `">
            <div class="ibox-filter" v-if="showFilter && !filterByResource">
                <div class="btn-search" @click="closeFilter">
                    <BackIconBigger v-if="biggerIcon" />
                    <BackIcon v-else />
                </div>

                <div class="header-divider"></div>

                <input type="number" v-show="showOrders" :placeholder="$t('Operator.SearchOrders')" v-model="textFilter"
                    @keyup.enter="getOrders(null)" ref="inputOrders" @focus="setInputFocusLog('inputOrders', true)"
                    @blur="setInputFocusLog('inputOrders', false)"
                >

                <input type="text" v-show="showResources" :placeholder="$t('Operator.SearchResources')"
                    v-model="textResource" @keyup.enter="getResources(null)" ref="inputResources"
                    @focus="setInputFocusLog('inputResources', true)"
                    @blur="setInputFocusLog('inputResources', false)"
                >

                <input type="number" class="input-search-finished" v-show="showFinished"
                    :placeholder="$t('Operator.SearchFinished')" v-model="textFinished"
                    @keyup.enter="getFinishedOrders(null)" ref="inputFinished"
                    @focus="setInputFocusLog('inputFinished', true)"
                    @blur="setInputFocusLog('inputFinished', false)"
                >
            </div>

            <span v-else>{{ $t('Orders') }}</span>

            <div class="ibox-actions">
                <div class="btn-search" v-if="!showFilter || filterByResource" @click="openFilter">
                    <SearchIconBigger v-if="biggerIcon" />
                    <SearchIcon v-else />
                </div>

                <div v-if="showOrders || showFinished" class="btn-select" @click="selectMode = true">
                    {{ $t('Select') }}
                </div>
            </div>
        </div>

        <b-collapse class="tabs-filter" v-model="showFilter" :style="`margin-top: ${marginTop}`">
            <div class="graph-buttons-resources" v-if="!filterByResource">
                <button type="button" :class="[
                    'btn-graph-resources',
                    showOrders
                        ? 'btn-active'
                        : 'btn-disabled',
                ]" @click="() => { showOrders = true; showResources = false; showFinished = false; }">
                    {{ $t('Orders') }}
                </button>

                <button type="button" :class="[
                    'btn-graph-resources',
                    showResources
                        ? 'btn-active'
                        : 'btn-disabled',
                ]" @click="() => { showResources = true; showOrders = false; showFinished = false }">
                    {{ $t('Operator.Resources') }}
                </button>

                <button type="button" :class="[
                    'btn-graph-resources',
                    showFinished
                        ? 'btn-active'
                        : 'btn-disabled',
                ]" @click="() => { showFinished = true; showOrders = false; showResources = false; }">
                    {{ $t('Operator.Finished') }}
                </button>
            </div>

            <div v-else class="div-clean-filter">
                <b-button class="btn-clean" @click="cleanFilter"> {{ $t('Operator.CleanFilter') }} </b-button>

                <div class="chip-resource">
                    {{ filterByResource.alias ? filterByResource.alias : filterByResource.name }}
                    <CloseFilter @click="cleanFilter" />
                </div>
            </div>
        </b-collapse>

        <div :class="{ 'div-content-menu-open': showFilter, 'div-content': !showFilter }">
            <Orders v-if="showOrders" @selectOrder="(id) => selectOrder(id)" @cleanSelectedOrder="cleanSelectedOrder"
                @loadMoreOrders="handleLoadMoreOrders" :selectMode.sync="selectMode" :orders="filteredOrders"
                :selectedOrders="orders.filter(f => f.selected)" :is-first-loading="isOrdersFirstLoading" />

            <Resources v-if="showResources" @onClickResource="filterOrdersByResource"
                @loadMoreResources="handleLoadMoreResources" :resources="filteredResources" />

            <FinishedOrders :finishedOrders.sync="finishedOrders" @selectItem="selectFinishedOrder" :selectMode.sync="selectMode" v-if="showFinished" @loadMoreFinishedOrders="handleLoadMoreFinishedOrders"
                :dateList.sync="filteredFinisheds"
                ref="finishedOrders"
                />
        </div>
    </div>
</template>

<script>
import { BCol, BRow, BCollapse, BButton } from 'bootstrap-vue'
import { mapMutations, mapGetters, mapActions } from "vuex";

import Resources from '@/views/pages/operator/components/Resources/index.vue'
import Orders from '@/views/pages/operator/components/OrderList.vue'
import FinishedOrders from '@/views/pages/operator/components/FinishedOrders.vue'
import Loading from '@/@core/components/loading';

export default {
    components: {
        BCol,
        BRow,
        BCollapse,
        BButton,
        SearchIcon: () => import('@/assets/images/icons/search-icon.svg'),
        SearchIconBigger: () => import('@/assets/images/icons/search-icon-bigger.svg'),
        BackIcon: () => import('@/assets/images/icons/back.svg'),
        CloseFilter: () => import('@/assets/images/icons/close-filter-icon.svg'),
        BackIconBigger: () => import('@/assets/images/icons/back-icon-bigger.svg'),
        Resources,
        Orders,
        FinishedOrders
    },
    data() {
        return {
            currentSite: JSON.parse(localStorage.getItem('currentSite')),
            prefix: localStorage.getItem('prefix') || this.$router.currentRoute.params.prefix,
            orders: [],
            resources: [],
            finishedOrders: [],
            showFilter: false,
            showOrders: true,
            showResources: false,
            showFinished: false,
            selectMode: false,
            filterByResource: null,
            filterByResourceBackup: null,
            biggerIcon: false,
            marginTop: '-90px',
            textFilter: '',
            textResource: '',
            textFinished: '',
            width: null,
            inputFocusLog: {
                inputOrders: false,
                inputResources: false,
                inputFinished: false,
            },
            isOrdersFirstLoading: true,
            isFirstLoading: true
        }
    },
    computed: {
        ...mapGetters('app', [
            'flagShowModalPin',
        ]),
        filteredOrders() {
            const filterResourceId = this.filterByResourceBackup?.id;
            const selectedOrders = this.orders.filter(f => f.selected);
            const disabledOrders = this.orders.filter(f => f.disabled);
            const orders = this.orders;

            if (selectedOrders.length > 0) {
                for (const order of selectedOrders) {
                    const absentOrders = orders.filter(order => !disabledOrders.includes(order));

                    if (order.control_by_hour == '1') {
                        for (let absentOrder of absentOrders) {
                            if (order.preactor_order_id !== absentOrder.preactor_order_id)
                                this.$set(absentOrder, 'disabled', true);
                        }
                    }
                    else {
                        for (let absentOrder of absentOrders) {
                            if (order.preactor_order_id !== absentOrder.preactor_order_id && (order.status != absentOrder.status || absentOrder.control_by_hour == '1'))
                                this.$set(absentOrder, 'disabled', true);
                        }
                    }
                }
            }

            const filteredOrders = !!filterResourceId
                ? this.orders.filter(order => order.preactor_resource_id == filterResourceId && order.found)
                : this.orders.filter(order => order.found);

            filteredOrders.sort(function (a, b) {
                if (a.selected === b.selected) {
                    return 0;
                } else if (a.selected) {
                    return -1;
                } else {
                    return 1;
                }
            });

            return filteredOrders;
        },
        filteredResources() {
            this.resources.sort(function (a, b) {
                const nameA = a.name.toUpperCase();
                const nameB = b.name.toUpperCase();

                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });

            return this.resources.filter(resource => resource.found);
        },
        filteredFinisheds() {
            const filteredOrders = this.finishedOrders.map(date => ({
                ...date,
                orders: date.orders.filter(order => order.found)
            })).filter(date => date.orders.length > 0);

            return filteredOrders;
        }
    },
    async created() {
        window.addEventListener("resize", this.getSize);
        this.getSize();

        await Promise.all([
            this.getOrders(null, null),
            this.getResources(null),
            this.getFinishedOrders(null)
        ])
        .finally(() => {
            this.isFirstLoading = false;
        });
    },
    methods: {
        ...mapMutations('app', [
            'UPDATE_FLAG_SHOW_OVERLAY'
        ]),
        ...mapActions('app', [
            'removeRequestLoading'
        ]),
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        },
        handleLoadMoreOrders() {
            if(this.isFirstLoading) return;

            const scroll = document.body.scrollHeight - 71.5; //desconsiderando footer da página

            if (scroll > window.innerHeight) {
                let ids = [];

                this.orders.forEach(order => {
                    if (!ids.includes(order.id))
                        ids.push(order.id);
                });

                ids.sort(function (a, b) {
                    return a - b;
                });

                let idOut = Number.parseInt(ids[0]);
                for (var j = 0; j < ids.length; j++) {
                    if (ids[j] != idOut + j) {
                        idOut = ids[j - 1];
                        break;
                    }
                }

                if (idOut == ids[0]) {
                    const lastOrderFrontend = ids[ids.length - 1];

                    if (lastOrderFrontend)
                        this.getOrders(lastOrderFrontend, this.filterByResource?.id);
                } else
                    this.getOrders(idOut, this.filterByResource?.id);
            }
        },
        handleLoadMoreResources() {
            const scroll = document.body.scrollHeight - 71.5; //desconsiderando footer da página

            if (scroll > window.innerHeight) {
                let ids = [];

                this.resources.forEach(order => {
                    if (!ids.includes(order.id))
                        ids.push(order.id);
                });

                ids.sort(function (a, b) {
                    return a - b;
                });

                let idOut = Number.parseInt(ids[0]);
                for (var j = 0; j < ids.length; j++) {
                    if (ids[j] != idOut + j) {
                        idOut = ids[j - 1];
                        break;
                    }
                }

                if (idOut == ids[0]) {
                    const lastResourceReturned = ids[ids.length - 1];

                    if (lastResourceReturned)
                        this.getResources(lastResourceReturned);
                } else
                    this.getResources(idOut);
            }
        },
        handleLoadMoreFinishedOrders() {
            const scroll = document.body.scrollHeight - 71.5; //desconsiderando footer da página

            if (scroll > window.innerHeight) {
                let ids = [];

                this.finishedOrders.forEach(date => {
                    date.orders.forEach(order => {
                        if (!ids.includes(order.id))
                            ids.push(order.id);
                    })
                });

                let idOut = Number.parseInt(ids[0]);
                for (var j = 0; j < ids.length; j++) {
                    if (ids[j] != idOut + j) {
                        idOut = ids[j - 1];
                        break;
                    }
                }

                if (idOut == ids[0]) {
                    const lastOrderReturned = ids[ids.length - 1];

                    if (lastOrderReturned)
                        this.getFinishedOrders(lastOrderReturned);
                } else
                    this.getFinishedOrders(idOut);
            }
        },
        async getOrders(lastOrderID = null, resourceId = null) {
            this.UPDATE_FLAG_SHOW_OVERLAY(true);

            try {
                const response = await this.$http.get(
                    `/api/${this.prefix}/operator/orders?site_guid=${this.currentSite.guid}
                        ${lastOrderID ? `&order_id=${lastOrderID}` : ''}
                        ${this.textFilter ? `&filter=${this.textFilter}` : ''}
                        ${resourceId ? `&preactor_resource_id=${resourceId}` : ''}`
                )

                let orders = response.data.response.filter(order => !this.orders.some(i => i.preactor_order_id === order.preactor_order_id));

                    if (this.textFilter) {
                        orders.forEach(order => {
                            this.$set(order, 'found', true);
                        });

                        this.orders = [
                            ...orders,
                            ...this.orders
                        ];
                    } else {
                        this.orders = [
                            ...this.orders,
                            ...orders,
                        ]

                        this.orders.forEach((obj) => {
                            this.$set(obj, 'found', true);
                        });
                    }

                this.isOrdersFirstLoading = false;
            } catch (error) {
                console.error(error);
            } finally {
                this.removeRequestLoading();

            }
        },
        async getResources(lastResourceID = null) {
            this.UPDATE_FLAG_SHOW_OVERLAY(true);

            try {
                const response = await this.$http.get(`/api/${this.prefix}/operator/resources?site_guid=${this.currentSite.guid}
                    ${lastResourceID ? `&id=${lastResourceID}` : ''}
                    ${this.textResource ? `&filter=${this.textResource}` : ''}`)

                let resources = response.data.response.filter(resource => !this.resources.some(i => i.preactor_resource_id === resource.preactor_resource_id));
                if (this.textResource) {
                    resources.forEach(resource => {
                        this.$set(resource, 'found', true);
                    });
                    this.resources = [
                        ...resources,
                        ...this.resources
                    ];
                } else {
                    this.resources = [
                        ...this.resources,
                        ...resources
                    ];
                    this.resources.forEach(resource => {
                        this.$set(resource, 'found', true);
                    })
                }
            } catch (error) {
                console.error(error)
            } finally {
                this.removeRequestLoading();
            }
        },
        async getFinishedOrders(lastOrderID = null) {
            this.UPDATE_FLAG_SHOW_OVERLAY(true);

            try {
                const response = await this.$http.get(`/api/${this.prefix}/operator/orders/completed?site_guid=${this.currentSite.guid}
                    ${lastOrderID ? `&order_id=${lastOrderID}` : ''}
                    ${this.textFinished ? `&filter=${this.textFinished}` : ''}`, {headers: {'IgnoreUUID': true}})

                let finishedOrders = response.data.response.filter(order => !this.finishedOrders.some(i => i.date === order.date));
                let finishedOrdersExistDate = response.data.response.filter(order => this.finishedOrders.some(i => i.date === order.date));
                if (this.textFinished) {
                    finishedOrders.forEach(date => {
                        date.orders.forEach(order => {
                            this.$set(order, 'found', true);
                        })
                    });
                    finishedOrdersExistDate.forEach(date => {
                        date.orders.forEach(order => {
                            this.$set(order, 'found', true);
                        })
                    });
                    finishedOrdersExistDate.forEach(existDate => {
                        let date = this.finishedOrders.find(f => f.date == existDate.date);
                        if (date) {
                            let ordersNotExist = existDate.orders.filter(f => !date.orders.some(i => i.preactor_order_id === f.preactor_order_id));
                            date.orders.push(...ordersNotExist);
                        }
                    });
                    this.finishedOrders = [
                        ...this.finishedOrders,
                        ...finishedOrders
                    ]
                }
                else {
                    finishedOrdersExistDate.forEach(existDate => {
                        let date = this.finishedOrders.find(f => f.date == existDate.date);
                        if (date) {
                            let ordersNotExist = existDate.orders.filter(f => !date.orders.some(i => i.preactor_order_id === f.preactor_order_id));
                            date.orders.push(...ordersNotExist);
                        }
                    });
                    this.finishedOrders = [
                        ...this.finishedOrders,
                        ...finishedOrders
                    ]
                    this.finishedOrders.forEach(date => {
                        date.orders.forEach(order => {
                            this.$set(order, 'found', true);
                        })
                    })
                }

            } catch (error) {
                console.error(error)
            } finally {
                this.removeRequestLoading();
            }
        },
        cleanFilter() {
            this.filterByResource = null;
            this.filterByResourceBackup = null;
            this.showFilter = false;

            if (this.textFilter)
                this.openFilter();
        },
        getSize() {
            this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

            if (this.width <= 768) {
                this.biggerIcon = this.width > 535 ? true : false;
                this.marginTop = this.width > 535 ? '-80px' : this.showFilter ? '-78px' : '-100px';
            }
            else {
                this.biggerIcon = false;
                this.marginTop = '-92px'
            }
        },
        filterOrdersByResource(resource) {
            this.filterByResource = {
                id: resource.preactor_resource_id,
                name: resource.name,
                alias: resource.alias
            };

            this.filterByResourceBackup = {
                id: resource.preactor_resource_id,
                name: resource.name
            };

            this.showFilter = true;
            this.showOrders = true;
            this.showResources = false;
            this.showFinished = false;
            this.textFilter = '';

            this.getOrders(null, resource.preactor_resource_id);
        },
        openFilter() {
            this.showFilter = true;
            this.filterByResource = null;

            this.$nextTick(() => {
                this.$refs.inputOrders.focus();
            })
        },
        closeFilter() {
            if (this.filterByResourceBackup)
                this.filterByResource = this.filterByResourceBackup;
            else
                this.showFilter = false;

            this.textFilter = '';
            this.textResource = '';
            this.textFinished = '';
            this.showOrders = true;
            this.showResources = false;
            this.showFinished = false;
        },
        filterOrders() {
            const { textFilter, orders } = this;

            if (textFilter) {
                orders.forEach(order => {
                    order.found = order.order_no.toLowerCase().includes(textFilter.toLowerCase())
                        || order.resource.toLowerCase().includes(textFilter.toLowerCase());
                });

                return;
            }

            orders.forEach(order => order.found = true);
        },
        filterResources() {
            const { textResource, resources } = this;

            if (textResource) {
                resources.forEach(resource => {
                    resource.found = resource.name.toLowerCase().includes(textResource.toLowerCase())
                        || !!resource.alias?.includes(textResource.toLowerCase());
                });

                return;
            }

            resources.forEach(resource => resource.found = true);
        },
        filterFinished() {
            const { textFinished, finishedOrders } = this;

            if (textFinished) {
                finishedOrders.forEach(date => {
                    date.orders.forEach(order => {
                        order.found = order.order_no.toLowerCase().includes(textFinished.toLowerCase())
                            || order.resource.toLowerCase().includes(textFinished.toLowerCase());
                    });
                });

                return;
            }

            finishedOrders.forEach(date => {
                date.orders.forEach(order => order.found = true);
            });
        },
        selectFinishedOrder({orderId, selectedOrdersCount}) {
            if (!orderId) return

            if (selectedOrdersCount === 0) {
                this.$refs.finishedOrders.resetOrders();
                return;
            }

            let order;
            for (let i = 0; i < this.finishedOrders.length; i++) {
                const finishedItemIndex = this.finishedOrders[i].orders.findIndex(item => item.preactor_order_id == orderId);
                if (finishedItemIndex !== -1) {
                    order = this.finishedOrders[i].orders[finishedItemIndex];
                    break;
                }
            }

            if (!order?.disabled) {
                if (order) {
                    this.$set(order, 'selected', !order.selected)
                }
            }

            // Verificar somente quando tiver 1 item selecionado para evitar
            // execuções desnecessárias
            if (selectedOrdersCount !== 1) return;

            this.finishedOrders.forEach((date) => {
                date.orders.forEach((item) => {
                    if(item.preactor_order_id == orderId) return;

                    if (order.control_by_hour === '1') {
                        this.$set(item, 'disabled', order.selected);
                        return
                    }

                    if (order.control_by_hour === '0' && (item.control_by_hour == '1' || item.is_rework != order.is_rework))
                        this.$set(item, 'disabled', order.selected);
                })
            })
        },
        selectOrder(id) {
            const order = this.orders.find(f => f.preactor_order_id === id);

            if (order.disabled || !order) return;

            this.$set(order, 'selected', !order.selected);

            this.orders.forEach(item => {
                if(item.preactor_order_id == id) return;

                if (order.control_by_hour === '1') {
                    this.$set(item, 'disabled', order.selected);
                    return;
                }

                if (order.control_by_hour === '0' && (item.status != order.status || item.control_by_hour == '1' || order.is_rework !== item.is_rework))
                    this.$set(item, 'disabled', order.selected);
            })
        },
        cleanSelectedOrder() {
            this.orders.forEach(order => {
                this.$set(order, 'selected', false);
                this.$set(order, 'disabled', false);
            })
        },
        addAndRemoveInputsFocus(action) {
            const { showOrders, showResources, showFinished, inputFocusLog } = this;
            const { inputOrders, inputResources, inputFinished } = this.$refs;

            const execAction = (input, inputNameRef) => {
                ( inputFocusLog[inputNameRef] && action === 'remove' ) && input?.blur();
                ( inputFocusLog[inputNameRef] && action === 'add' ) && input.focus();
            };

            showOrders && execAction(inputOrders, 'inputOrders');
            showResources && execAction(inputResources, 'inputResources');
            showFinished && execAction(inputFinished, 'inputFinished');
        },
        setInputFocusLog(inputName, value) {
            if(!this.flagShowModalPin) this.inputFocusLog[inputName] = value;
        }
    },
    watch: {
        showOrders(value) {
            if (!!value) {
                this.scrollToTop();

                this.$nextTick(() => {
                    let input = this.$refs.inputOrders;

                    if (input)
                        input.focus();
                })
            }
        },
        showFinished(value) {
            if (!!value) {
                this.scrollToTop();

                this.$nextTick(() => {
                    this.$refs.inputFinished.focus();
                })
            }
        },
        showResources(value) {
            if (!!value) {
                this.scrollToTop();

                this.$nextTick(() => {
                    this.$refs.inputResources.focus();
                })
            }
        },
        textFilter() {
            this.filterOrders();
        },
        textResource() {
            this.filterResources();
        },
        textFinished() {
            this.filterFinished();
        },
        flagShowModalPin(value) {
            this.addAndRemoveInputsFocus( value ? 'remove' : 'add' );
        },
    },
    updated() {
        this.getSize();
    }
}
</script>

<style lang="scss" scoped>
.container-operator-orders {
    .div-content {
        margin-top: 4rem;
    }

    .div-content-menu-open {
        margin-top: 8rem;
    }

    .ibox-actions {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .operator-orders-header {
        padding: 16px 29px;
        position: fixed;
        margin-top: -105px;
        right: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        font-family: "Raleway";
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;

        background: #FFFFFF;
        color: #4C4541;
        z-index: 2;
        border-top: 1px solid #cfc4be;

        &:has(.input-search-finished) {
                gap: 16px !important;
                .ibox-filter, .input-search-finished {
                    flex: 1 !important;
                }
            }
    }

    .tabs-filter {
        width: 100vw;
        padding: 0px;
        position: fixed;
        margin-left: -28px;
        background: #FFFFFF;
        box-shadow: 0px 8px 30px rgba(58, 46, 38, 0.1);
        z-index: 1;

        .graph-buttons-resources {
            width: 100%;
            display: flex;
            justify-content: space-between;
            height: 58px;
            overflow-x: auto;

            &::-webkit-scrollbar {
                display: none !important;
            }

            .btn-graph-resources {
                min-width: 120px;
                font-size: 16px;
                border: none;
            }

            .btn-active {
                width: 100%;
                background-color: transparent;
                font-weight: 600;
                font-size: 16px;
                line-height: 26px;
                color: #974900;
                box-shadow: inset 0px -4px 0px #974900;
            }

            .btn-disabled {
                width: 100%;
                background-color: transparent;
                font-weight: 600;
                font-size: 16px;
                line-height: 26px;
                color: #998F8A;
                border-bottom: 1px solid #CFC4BE;
            }
        }

        .div-clean-filter {
            display: flex;
            align-items: center;
            height: 62px;
            padding-left: 30px;
            gap: 18px;

            .btn-clean {
                width: 102px;
                height: 28px;
                background: #974900 !important;
                padding: 2px 14px;
                border-radius: 5px;

                font-weight: 600;
                font-size: 12px;
                line-height: 20px;
                color: #FFFFFF !important;
                border: none !important;

                &:hover {
                    box-shadow: none;
                    background: #9F5714 !important;
                }
            }

            .chip-resource {
                padding: 6px 8px 6px 12px;
                height: 32px;
                background: #FFDBC4;
                border-radius: 8px;
                display: flex;
                align-items: center;
                font-weight: 600;
                font-size: 12px;
                line-height: 20px;
                color: #974900;
                gap: 8px;

                svg {
                    cursor: pointer;
                }
            }
        }
    }

    .btn-search {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        cursor: pointer;
        width: 38px;
        height: 38px;

        &:hover {
            background-color: #FFEDE2;
        }
    }

    .btn-select {
        cursor: pointer;
        width: 110px;
        height: 38px;
        font-size: 14px;
        line-height: 20px;
        border-radius: 5px;
        flex-direction: row;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 0.0125em;
        font-feature-settings: 'pnum' on, 'lnum' on;

        color: #974900;

        &:hover {
            background-color: #FFEDE2;
        }
    }

    .ibox-filter {
        display: flex;
        align-items: center;
        gap: 20px;

        input {
            border: none;
            color: #CFC4BE;
            font-size: 14px;
            line-height: 20px;
            height: 20px;

            &:focus {
                outline: none;
            }

            &::placeholder {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #CFC4BE;
            }
        }

        .input-search-finished {
            max-width: unset !important;
            width: unset !important;
        }
    }

    .header-divider {
        width: 1px;
        height: 30px;
        background: #CFC4BE;
    }
}

@media screen and (max-width: 768px) {
    .container-operator-orders {
        .operator-orders-header {
            font-size: 36px;
            line-height: 50px;
            height: 82px;
        }

        .btn-search {
            width: 63px;
            height: 63px;
        }

        .btn-select {
            width: 166px;
            height: 63px;
            font-size: 20px;
            line-height: 23px;
        }

        .ibox-filter {
            input {
                font-size: 22px;
                line-height: 30px;

                &::placeholder {
                    font-size: 22px;
                    line-height: 30px;
                }
            }
        }

        .header-divider {
            height: 50px;
        }

        .tabs-filter {
            .div-clean-filter {
                overflow-x: scroll;
                white-space: nowrap;
                scrollbar-width: 0px;
                -ms-overflow-style: none;
                padding: 0px 16px 0px 32px;

                &::-webkit-scrollbar {
                    display: none !important;
                }

                .btn-clean {
                    font-size: 14px;
                    line-height: 20px;
                    width: 129px;
                }

                .chip-resource {
                    font-size: 17px;
                    line-height: 20px;
                    cursor: grab;
                }
            }
        }
    }
}

@media screen and (max-width: 575px) {
    .container-operator-orders {
        .operator-orders-header {
            padding: 16px;
        }

        .tabs-filter {
           margin-left: -16px;
        }
    }
}

@media screen and (max-width: 535px) {
    .container-operator-orders {
        .div-content {
            margin-top: 3rem;
        }

        .div-content-menu-open {
            margin-top: 6.6rem;
        }

        .operator-orders-header {
            font-size: 18px;
            line-height: 26px;
            height: 62px;
            margin-top: -90px;
        }

        .btn-search {
            width: 28px;
            height: 28px;
        }

        .btn-select {
            width: 86px;
            height: 28px;
            font-size: 12px;
            line-height: 20px;
        }

        .ibox-filter {
            input {
                font-size: 12px;
                line-height: 16px;

                &::placeholder {
                    font-size: 12px;
                    line-height: 16px;
                }
            }
        }

        .header-divider {
            height: 26px;
        }

        .tabs-filter {
            .graph-buttons-resources {
                height: 52px;

                .btn-active,
                .btn-disabled {
                    font-size: 14px;
                    line-height: 20px;
                }
            }

            .div-clean-filter {
                padding: 16px;
                gap: 15px;

                .btn-clean {
                    font-size: 12px;
                    line-height: 20px;
                    width: 102px;
                    height: 28px;
                }

                .chip-resource {
                    font-size: 12px;
                    line-height: 20px;
                }
            }
        }
    }
}
</style>
