<template>
    <div>
        <NotFound v-if="dateList.length === 0"/>

        <div :class="['item-list', {'padding-of-the-float': selectMode}]" v-else>
            <div
                v-for="(date, index1) in dateList"
                class="item-container"
                :key="`date-card-${index1}`"
            >
                <div
                    class="date-bar date-anchor"
                    :id="date.date"
                    v-if="index1 !== 0"
                >
                    <!-- função $t usada para garantir a tradução da data quando o idioma for trocado -->
                    <span class="filtered-date">{{ $t(getFormatedDate(date.date)) }}</span>
                </div>

                <div class="order-list">
                    <div
                        v-for="(order, index2) in date.orders"
                        :key="`order-card-1${index2}`"
                        @click="pressCard(order)"
                    >
                        <OrderCard :selectable="selectMode" :order="order" orderType="Finished" />
                    </div>
                </div>
            </div>

            <Observer @intersect="$emit('loadMoreFinishedOrders')" />

            <div
                class="date-bar float-bar"
                id="date-float-bar"
                v-if="dateList.length"
            >
                <!-- função $t usada para garantir a tradução da data quando o idioma for trocado -->
                <span class="filtered-date">
                   {{ $t(getFormatedDate(floatDate.date)) }}
                </span>
            </div>
        </div>
        <div v-if="selectMode" class="float-bar-select">
        <span v-show="selectedOrders.length === 0" class="filtered-orders">
          {{ $t("Operator.FinishedOrdersBar.Count.NoOrderSelected") }}
        </span>
        <span v-show="selectedOrders.length === 1" class="filtered-orders">
          {{ selectedOrders.length }}
          {{ $t("Operator.FinishedOrdersBar.Count.SelectedOrder") }}
        </span>
        <span v-show="selectedOrders.length > 1" class="filtered-orders">
          {{ selectedOrders.length }}
          {{ $t("Operator.FinishedOrdersBar.Count.SelectedOrders") }}
        </span>

        <div class="box-btn">
          <button class="btn-cancel" @click="cancelSelection">
            {{ $t("Operator.FinishedOrdersBar.Cancel") }}
          </button>

          <button class="btn-clean" @click="cleanSelectedOrder">
            {{ $t("Operator.FinishedOrdersBar.Clean") }}
          </button>

          <button
            :disabled="selectedOrders.length === 0"
            class="btn-point"
            @click="redirectAppointmentsRework"
          >
            {{ $t("Operator.FinishedOrdersBar.Rework") }}
          </button>
        </div>
      </div>
    </div>
</template>

<script>

import OrderCard from './OrderCard.vue';
import moment from 'moment';
import { mapMutations } from 'vuex';
import Observer from '../events/Observer.vue';
import NotFound from './NotFound.vue';


export default {
    components: {
        OrderCard,
        Observer,
        NotFound,
    },
    props: {
        dateList: {
            type: Array,
        },
        selectMode: {
            type: Boolean
        },
        finishedOrders: {
            type: Array
        }
    },
    data() {
        return {
            prefix: localStorage.getItem('prefix') || this.$router.currentRoute.params.prefix,
            floatDate: {
                lastIndex: null,
                date: ''
            },
            selectedOrders: []
        }
    },
    watch: {
        dateList (value) {
            if (value.length > 0 && this.floatDate.lastIndex && value[this.floatDate.lastIndex + 1]) {
                this.floatDate.date = value[this.floatDate.lastIndex + 1].date;
            }
            else {
                this.floatDate.date = value[0].date;
            }

            this.checkOverlap()
        }
    },
    mounted() {
        this.UPDATE_FLAG_SHOW_OVERLAY(false);
        this.scroll();

        this.floatDate.date = this.dateList.length > 0 ? this.dateList[0].date : '';
    },
    methods: {
        ...mapMutations("app", [
            "UPDATE_FLAG_SHOW_OVERLAY"
        ]),
        redirectAppointmentsRework() {
            const isMultipleOrders = this.selectedOrders.length > 1;

            const routerParams = {
                name: isMultipleOrders
                ? "operator-production-progress-multiple"
                : "operator-production-progress",
                params: {
                prefix: this.prefix,
                resource_id: this.selectedOrders[0].resource_id,
                multiple: this.selectedOrders.length > 1,
                orders: isMultipleOrders ? this.selectedOrders : [],
                appointment_type: 1, // Sempre será retrabalho
                },
            };

            if (isMultipleOrders) {
                routerParams.params.preactor_orders = this.selectedOrders
                .map((item) => item.preactor_order_id)
                .join(",");
            }

            if (!isMultipleOrders) {
                routerParams.params.return_orders = "true";
                routerParams.params.order_id = this.selectedOrders[0].preactor_order_id;
            }
            this.$router.push(routerParams);
        },
        resetOrders() {
            const updatedFinished = JSON.parse(JSON.stringify(this.finishedOrders))
            updatedFinished.forEach((date) => {
                date.orders.forEach((order) => {
                    this.$set(order, 'disabled', false);
                    this.$set(order, 'selected', false);
                })
            })
            this.$emit('update:finishedOrders', updatedFinished)
        },
        cancelSelection() {
            this.selectedOrders = []
            this.$emit("update:selectMode", false);
            this.resetOrders();

        },
        cleanSelectedOrder() {
            this.selectedOrders = [];
            this.resetOrders();
        },
        scroll() {
            window.onscroll = () => {
                this.checkOverlap();
            }
        },
        redirectOrder(resource_id, preactor_order_id, appointment_type) {
            const routerParams = {
                name: 'operator-production-progress',
                params: {
                    prefix: this.prefix,
                    resource_id: resource_id,
                    order_id: preactor_order_id,
                    multiple: 'false',
                    return_orders: 'true',
                    orders: [],
                    appointment_type: appointment_type
                }
            };

            this.$router.push(routerParams);
        },
        pressCard(item) {
            if (this.selectMode) {
                this.selectOrder({resource_id: item?.resource_id, preactor_order_id: item?.preactor_order_id, disabled: !!item?.disabled})
                return;
            }
            this.redirectOrder(item?.resource_id, item?.preactor_order_id, item.is_rework);
        },
        selectOrder({ preactor_order_id, resource_id, disabled }) {
            if (disabled) return
            const isAlreadySelected = this.selectedOrders.some(item => item.preactor_order_id === preactor_order_id)
            if (!isAlreadySelected) {
                this.selectedOrders.push({preactor_order_id, resource_id})
                this.$emit('selectItem', {orderId: preactor_order_id, selectedOrdersCount: this.selectedOrders.length})
            } else {
                this.selectedOrders = this.selectedOrders.filter(item => item.preactor_order_id !== preactor_order_id)
                this.$emit('selectItem', {orderId: preactor_order_id, selectedOrdersCount: this.selectedOrders.length})
            }
        },
        getFormatedDate(date) {
            if(!date) return '';

            const language = localStorage.getItem('language');
            if (language == 'pt-BR') return moment(date).format('DD/MM');

            return moment(date).format('MM/DD');
        },
        checkOverlap() {
            const fixedDiv = document.getElementById('date-float-bar');
            const dateListDiv = document.querySelectorAll('.date-anchor');
            const fixedRect = Math.round(fixedDiv?.getBoundingClientRect().top);

            let lastIndex = this.floatDate.lastIndex;

            // Verifica se há menos de duas datas na lista
            if (dateListDiv.length === 0) return;

            // Verifica se o elemento fixo está acima da primeira data
            if (fixedRect < Math.round(dateListDiv?.[0]?.getBoundingClientRect().top)) {
                dateListDiv[0].classList.remove('hide-date');
                this.floatDate.date = this.dateList[0].date;
                this.floatDate.lastIndex = null;
                return;
            }

              // Verifica se o elemento fixo está entre a primeira e segunda data
            if (
                (fixedRect > Math.round(dateListDiv?.[0]?.getBoundingClientRect()?.top) &&
                fixedRect < Math.round(dateListDiv?.[1]?.getBoundingClientRect()?.top))
                || (fixedRect > Math.round(dateListDiv?.[0]?.getBoundingClientRect()?.top) && dateListDiv.length === 1)
            ) {
                dateListDiv[0]?.classList.add('hide-date');
                dateListDiv[1]?.classList.remove('hide-date');
                this.floatDate.date = dateListDiv[0].id;
                this.floatDate.lastIndex = 0;
                return;
            }

            // Verifica se o elemento fixo está acima da última data flutuante
            if (fixedRect < Math.round(dateListDiv?.[lastIndex]?.getBoundingClientRect()?.top)) {
                dateListDiv[lastIndex].classList.remove('hide-date');
                dateListDiv[lastIndex - 1].classList.add('hide-date');
                this.floatDate.lastIndex = lastIndex -= 1;
                this.floatDate.date = dateListDiv[lastIndex - 1].id;
                return;
            }

              // Verifica se o próximo índice é maior que o comprimento da lista de datas
            if (lastIndex + 1 > dateListDiv.length - 1) return;

              // Verifica se o índice é maior que o comprimento da lista de datas
            if (fixedRect > Math.round(dateListDiv?.[lastIndex + 1]?.getBoundingClientRect()?.top)) {
                dateListDiv[lastIndex + 1].classList.add('hide-date');
                dateListDiv[lastIndex].classList.remove('hide-date');
                this.floatDate.date = dateListDiv[lastIndex + 1].id;
                this.floatDate.lastIndex += 1;
            }
        }
    },
}
</script>

<style lang="scss" scoped>

.float-bar-select {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 15px;

  background: #ffffff;
  border: 1px solid #cfc4be;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12),
    0px 3px 5px rgba(0, 0, 0, 0.2);
  border-radius: 6px;

  position: fixed;
  bottom: 71.5px;
  z-index: 10;
  font-size: 12px;
  outline: none;
  right: 22px;
  width: 100%;
  max-width: 662px;

  .filtered-orders {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #4c4541;
    flex: 1;
  }
  .box-btn {
  display: flex;
  gap: 16px;
  justify-content: end;

  button {
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    border-radius: 5px;
    padding: 8px 20px;
    border: 1px solid transparent;
    letter-spacing: 0.0125em;
  }

  .btn-cancel {
    background: #fff;
    color: #974900;

    &:hover {
      background-color: #ffede2 !important;
    }

    &:active {
      background-color: #ffdbc4 !important;
    }
  }

  .btn-clean {
    color: #974900;
    background-color: #ffffff;
    border-color: #974900;

    &:hover {
      background-color: #ffede2 !important;
    }

    &:active {
      background-color: #ffdbc4 !important;
    }
  }

  .btn-point {
    color: #ffffff !important;
    border-color: #974900 !important;
    background-color: #974900 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    &:disabled {
      background: #ece0db !important;
      color: #7e7570 !important;
      border: transparent !important;
      padding: 8px 21px;

      svg,
      path {
        fill: #7e7570 !important;
      }

      &:hover,
      &:active {
        background: #ece0db !important;
        color: #7e7570 !important;
      }
    }

    &:hover {
      background-color: #9f5714 !important;
    }

    &:active {
      background-color: #a45f1f !important;
    }
  }
}
}

.hide-date {
    opacity: 0;
}

.item-list {
    width: 100%;
    padding: 55px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    gap: 16px 0;

    &.padding-of-the-float {
        padding-bottom: 70px !important;
    }

    .item-container {
        display: flex;
        flex-direction: column;
        gap: 16px 0;
    }

    .order-list {
        display: flex;
        flex-direction: column;
        gap: 16px 0;
    }
}

.float-bar {
    width: calc(100% - 42px) !important;
    filter: drop-shadow(0px 4px 24px rgba(58, 46, 38, 0.1));
    position: fixed;
    top: 194px;
    z-index: 10;
    font-size: 12px;
    outline: none;
    margin-top: 8px;
}

.date-bar {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;

    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(58, 46, 38, 0.1);
    border-radius: 6px;
    padding: 16px;

    .filtered-date {
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        color: #4C4541;
        margin-right: auto;
    }
}

@media (max-width: 768px) {
    .float-bar {
        margin-top: 26px !important;
        width: calc(100% - 60px) !important
    }
}

@media (max-width: 768px) and (min-width: 641px){
    .float-bar-select {
        .box-btn  {
            button {
                font-size: 17px !important;
            }
            .btn-point {
                width: 147px !important;
            }
            .btn-clean {
                padding: 13px 27px !important;
            }
            .btn-cancel {
                width: 130px !important;
            }
        }
    }
}

@media (max-width: 768px) and (min-width: 426px) {
    .item-list {
        padding-top: 80px !important;
    }
    .filtered-date {
        font-size: 22px !important;
    }

    .float-bar {
        flex-direction: column;
        height: auto;
    }
}

@media (max-width: 768px) and (min-width: 593px) {
    .item-list {
        &.padding-of-the-float {
            padding-bottom: 78px !important;
        }
    }

    .float-bar-select {
    right: 0;
    left: 0;
    width: calc(100% - 58px);
    margin: 0 auto;
    gap: 16px !important;
    max-width: unset !important;

    .filtered-orders {
      font-size: 18px;
    }

  .btn-clean {
    height: 46px !important;
  }

  .btn-point {
    height: 46px !important;
    line-height: 33px !important;
    padding: 13px 28px !important;
  }
}



}

@media (max-width: 593px) {
    .item-list {
        &.padding-of-the-float {
            padding-bottom: 96px !important;
        }
    }
    .float-bar {
        width: calc(100% - 46px) !important;
    }

      .float-bar-select {
    left: 6px;
    margin: 0 auto;
    flex-direction: column;
    width: calc(100vw - 12px);
    height: auto;
    gap: 0;

    .filtered-orders {
      margin: 0;
    }

    .box-btn {
      padding: 16px 0 0 0;

      button {
        padding: 3px 13px !important;
        font-size: 12px !important;
        line-height: 20px !important;
      }
      .btn-point {
        padding: 4px 14px !important;
    }
    }
  }
}

@media (max-width: 535px) {
    .float-bar {
        margin-top: 8px !important;
    }
}

@media (max-width: 425px) {
    .item-list {
        padding-top: 72px !important;
    }
    .float-bar {
        width: calc(100% - 32px) !important;
    }

    .filtered-date {
        margin: 0;
        font-size: 14px !important;
        line-height: 20px !important;
    }
}

</style>
